import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  Text,
  TextProps,
  Heading,
  VStack,
  Center,
  Box,
} from "@chakra-ui/react";

import { ContentContainer } from "../components";
import { MainLayout } from "../layouts/Main";

const IdeaContainer = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <VStack align="flex-start" spacing="4">
      <Heading as="h2" fontSize="2xl">
        {title}
      </Heading>
      {children}
    </VStack>
  );
};

IdeaContainer.Text = (props: TextProps) => (
  <Text fontSize="xl" lineHeight="8" {...props} />
);

const WhyBasiliumPage = () => {
  return (
    <MainLayout
      title="Why Basilium was created"
      description="Basilium was designed with simplicity, usability, and low-carb diets in mind. If you’re looking for a simple tool that takes the hassle out of nutrition and weight tracking, Basilium could be the perfect match."
    >
      <ContentContainer>
        <Heading as="h1" mb="10">
          Why Basilium?
        </Heading>
        <Text fontSize="xl" my="4">
          Hey there!
        </Text>
        <Text fontSize="xl" lineHeight="8">
          I'm Daniel, the creator of Basilium. I want to share with you why
          Basilium was created and why it might be the perfect fit for you.
        </Text>
      </ContentContainer>
      <Center my="14">
        <Box maxW="3xl">
          <StaticImage
            src="../assets/images/basilium-screens-composition.png"
            alt="Basilium: minimalist low-carb nutrition tracker"
          />
        </Box>
      </Center>
      <ContentContainer>
        <VStack align="flex-start" spacing="10">
          <IdeaContainer title="Cutting out the clutter">
            <IdeaContainer.Text>
              Have you ever noticed that nutrition trackers these days are
              trying to do it all? They've become a mishmash of social media,
              food blogs, recipe books, workout trackers — you name it. Having
              all these features at your fingertips may sound great, but here's
              the catch: it comes at a cost. And not just in terms of money but
              also in terms of complexity.
            </IdeaContainer.Text>
            <IdeaContainer.Text>
              The truth is most of us don't enjoy tracking what we eat. If an
              app is too complicated or overwhelming, we'll keep putting it off
              until "later." But guess what? Later never comes. This defeats the
              whole purpose of a nutrition tracker and leaves us struggling to
              truly benefit from the value it could bring to our lives.
            </IdeaContainer.Text>
            <IdeaContainer.Text>
              That's where Basilium comes in. Basilium was born out of
              frustration with complex and overwhelming nutrition apps. I asked
              myself, what if we stripped away all the unnecessary stuff and
              focused only on the essentials? And that's exactly what Basilium
              is all about.
            </IdeaContainer.Text>
          </IdeaContainer>
          <IdeaContainer title="Simplicity is key">
            <IdeaContainer.Text>
              Basilium was designed to be simple and user-friendly. Daily tasks
              like logging your food or weight have been simplified to their
              core. Each screen is designed to show you the most important
              information at a glance, and every interactive element is placed
              for easy use.
            </IdeaContainer.Text>
            <IdeaContainer.Text>
              Basilium's food database was carefully curated to include only
              natural and low-carb foods. This means you won't waste time
              searching through millions of irrelevant or duplicate options.
              Instead, you'll have a clean and easily searchable database
              tailored to those following healthy low-carb diets.
            </IdeaContainer.Text>
          </IdeaContainer>
          <IdeaContainer title="Sustainable and mindful tracking">
            <IdeaContainer.Text>
              Basilium isn't just about simplifying nutrition tracking. It's
              about creating a sustainable habit that brings awareness to your
              nutrition. By keeping a food diary, you'll start to pay closer
              attention to what and how much you eat. This newfound mindfulness
              will help you better understand the nutritional value of your
              food, control your portions, and achieve your weight goals.
            </IdeaContainer.Text>
          </IdeaContainer>
          <IdeaContainer title="Is Basilium right for you?">
            <IdeaContainer.Text>
              Basilium may not be revolutionary, but its minimalist approach
              sets it apart. If you're tired of complicated and overwhelming
              nutrition trackers and just want a simple tool that takes the
              hassle out of nutrition and weight tracking, then Basilium could
              be your perfect match.
            </IdeaContainer.Text>
            <IdeaContainer.Text>
              Give Basilium a try and experience the simplicity and
              effectiveness of minimalism in nutrition tracking.
            </IdeaContainer.Text>
          </IdeaContainer>
        </VStack>
      </ContentContainer>
    </MainLayout>
  );
};

export default WhyBasiliumPage;
